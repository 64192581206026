
.lastChanged {
  color: white;
}

.App {
  margin-top: 20px;
  padding: 10px;
  padding-top: 35px;
}

.crossout {
outline: 9px solid red;
border-radius: 19px;
}
.crossout div {
  background-color: pink;
}