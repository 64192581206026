@font-face {
  font-family: 'SF UI Display';
  src: url('./fonts/SFUIDisplay-Thin.woff2') format('woff2'),
  url('./fonts/SFUIDisplay-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Text';
  src: url('./fonts/SFUIText-UltralightItalic.woff2') format('woff2'),
  url('./fonts/SFUIText-UltralightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Text';
  src: url('./fonts/SFUIText-Ultralight.woff2') format('woff2'),
  url('./fonts/SFUIText-Ultralight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Text';
  src: url('./fonts/SFUIText-BoldItalic.woff2') format('woff2'),
  url('./fonts/SFUIText-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('./fonts/SFUIDisplay-Semibold.woff2') format('woff2'),
  url('./fonts/SFUIDisplay-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Text';
  src: url('./fonts/SFUIText-MediumItalic.woff2') format('woff2'),
  url('./fonts/SFUIText-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('./fonts/SFUIDisplay-Heavy.woff2') format('woff2'),
  url('./fonts/SFUIDisplay-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Text';
  src: url('./fonts/SFUIText-Bold.woff2') format('woff2'),
  url('./fonts/SFUIText-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('./fonts/SFUIDisplay-Ultralight.woff2') format('woff2'),
  url('./fonts/SFUIDisplay-Ultralight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('./fonts/SFUIDisplay-Bold.woff2') format('woff2'),
  url('./fonts/SFUIDisplay-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('./fonts/SFUIDisplay-Medium.woff2') format('woff2'),
  url('./fonts/SFUIDisplay-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Text';
  src: url('./fonts/SFUIText-SemiboldItalic.woff2') format('woff2'),
  url('./fonts/SFUIText-SemiboldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('./fonts/SFUIDisplay-Black.woff2') format('woff2'),
  url('./fonts/SFUIDisplay-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Text';
  src: url('./fonts/SFUIText-Medium.woff2') format('woff2'),
  url('./fonts/SFUIText-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Text';
  src: url('./fonts/SFUIText-Heavy.woff2') format('woff2'),
  url('./fonts/SFUIText-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Text';
  src: url('./fonts/SFUIText-Semibold.woff2') format('woff2'),
  url('./fonts/SFUIText-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Text';
  src: url('./fonts/SFUIText-LightItalic.woff2') format('woff2'),
  url('./fonts/SFUIText-LightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Text';
  src: url('./fonts/SFUIText-Regular.woff2') format('woff2'),
  url('./fonts/SFUIText-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Text';
  src: url('./fonts/SFUIText-RegularItalic.woff2') format('woff2'),
  url('./fonts/SFUIText-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Text';
  src: url('./fonts/SFUIText-HeavyItalic.woff2') format('woff2'),
  url('./fonts/SFUIText-HeavyItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('./fonts/SFUIDisplay-Regular.woff2') format('woff2'),
  url('./fonts/SFUIDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('./fonts/SFUIDisplay-Light.woff2') format('woff2'),
  url('./fonts/SFUIDisplay-Light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Text';
  src: url('./fonts/SFUIText-Light.woff2') format('woff2'),
  url('./fonts/SFUIText-Light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}


html {
  font-family: 'SF UI Display';
}