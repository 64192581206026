.Timeline {
  font-family: Helvetica;
  .dayheader {
    text-align: center;
    color: purple;
    text-shadow: 0 0 7px #fff,0 0 10px #fff,0 0 21px #fff;
    font-size: 16px;
  }

  .rbc-time-header {
    display: none;
  }
  .rbc-time-content {
    border: none;
  }
  .rbc-calendar {
    border: none;
  }
  .rbc-current-time-indicator {
    border: 2px solid red;
  }
  .rbc-time-view {
    border: none;
  }
  .rbc-events-container {
    border: none;
  }
  .rbc-event-content {
    font-size: 14px;
    display: inline;
    font-weight: bold;
  }
  .rbc-event-label {
    overflow: hidden;
    font-size: 16px;
    display: inline;
  }

  .rbc-time-gutter.rbc-time-column {
    display: none;
  }
  .rbc-time-header .rbc-time-header-content {
    border-left: none;
  }

  .rbc-timeslot-group {
    border: none;
  }
  .rbc-day-slot .rbc-time-slot {
    border: none;
  }
  .rbc-today {
    background-color: rgba(0,0,0,0);
  }
}

