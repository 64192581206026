
.Dash {
  min-height: 100vh;
  background-color: #d577bf;
  padding-left: 5px;
}

.Dash .title {
  color: white;
}
.Dash .total {
  float: right;
  color: white;
}

.Dash .history * svg {
  padding: 4px;
}


.Plot {
  display:inline-block;
  position:relative;
  .Label {
    color: white;
  }
}

.Clock {
  width: 65vw;
  max-width: 65vw;
  white-space: nowrap;
  font-family: Helvetica;
  overflow: hidden;
  font-size: 5em;
  color: white;
}

.c1, .c2 {
  font-size: 40px;
  padding: 5px;
  span {
    font-size: 20px;
    position: relative;
    top: -15px;
  }
}

.differentoutside {
  background-color: red;
  color: white;
  width: 100%;
  padding: 5px;
  text-align: center;
  margin: 0;
}

/*
.TimeSeriesGraph {
  background-color: #c269b3;
  position: relative;
  .Axis {
    position: absolute;
    bottom: 5px;
    left: 0;
    .Node {
      text-align: center;
      position: absolute;
      bottom: 0;
      color: white;
      font-size: 0.6em;
    }
  }
  .Series {
    .Node {
      position: absolute;
      bottom: 18px;
      &.dMinus0 {
        background-image: linear-gradient(to top, rgba(0,0,0,0), white);
        z-index: 4;
      }
      &.dMinus1 {
        background-image: linear-gradient(to top, rgba(0,0,0,0), pink);
        opacity: 0.25;
        z-index: 3;
      }
      &.dMinus2 {
        background-image: linear-gradient(to top, rgba(0,0,0,0), pink);
        opacity: 0.125;
        z-index: 2;
      }
    }
  }
}
*/

.Dash .History {
  display: inline-block;
  background-color: #b363a5;
  margin: 5px;
/*  border-top: 1px solid transparent;*/
/*
  border-left: 1px solid #ffffff55;
  border-right: 1px solid #ffffff55;
  border-bottom: 1px solid #ffffff55;
*/
/*  padding: 5px;*/
/*  border-radius: 10px 0 10px 10px;*/
  border-radius: 10px;
  position: relative;
  color: #dbf0d9;
  .TitleBar {
    border-radius: 10px 0 0 0;
    padding: 2px;
    font-size: 20px;
    margin: 0;
    height: 25px;
/*    margin: 120px;*/
    background-color: #884980;
    box-shadow: 0 4px 4px -4px #00000044;
  }

  .cost {
    font-size: 0.8em;
    position: absolute;
    right: 4px;
    top: 2px;
  }
  .energy {
    font-size: 0.8em;
    position: absolute;
    right: 4px;
    top: 14px;
  }
}

.EH {
  display: inline-block;
  margin: 5px;
}

.Graph {
  margin: 5px;
  position: relative;
  background-color: #a4c4cb20;
  .GraphElement {
    border-color: white;
    border-style: solid;
    position: absolute;
    bottom: 8px;
    border-radius: 3px;
    background-image: linear-gradient(to top, rgba(0,0,0,0), white);
    z-index: 4;
    &.dMinus1 {
      background-image: linear-gradient(to top, rgba(0,0,0,0), pink);
      opacity: 0.25;
      z-index: 3;
    }
    &.dMinus2 {
      background-image: linear-gradient(to top, rgba(0,0,0,0), pink);
      opacity: 0.25;
      z-index: 2;
    }
  }
  .GraphElementLabel {
    letter-spacing: -1px;
    color:pink;
    font-size: 0.5em;
    text-align: center;
    position: absolute;
    &.dMinus1 {
      display: none;
    }
    &.dMinus2 {
      display: none;
    }
  }
  .GraphElementValueLabel {
    letter-spacing: -1px;
    color:pink;
    text-align: left;
    transform: rotate(-90deg);
    margin-bottom: 1.5em;
    font-size: 0.5em;
    position: absolute;
    &.dMinus1 {
      display: none;
    }
    &.dMinus2 {
      display: none;
    }
  }
}
